import { useMemo, useState } from "react";
import HeaderHooks from "./HeaderHooks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Dailyrate from "./DailyRate";
import Search from "./Search";
import Videorequest from "./VideoRequest";
import "./Header.css";
import $ from "jquery";
import { ProductService } from "../../../services/ProductService";
import { UPDATE_ALL_FILTER } from "../../../actions/types";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Menu = ({ count: propCount, cart: propCart }) => {
  const [showMenu, setShowMenu] = useState(false);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openMenuData = () => {
    setShowMenu(!showMenu);
  };
  const {
    header_icon2,
    header_icon4,
    header_icon6,
    header_icon5,
    header_icon7,
    header_icon8,
    header_branches,
    header_icon_scheme,
    showDailyRate,
    openDailyRate,
    closeDailyRate,
    showSearchBar,
    openSearch,
    closeSearch,
    showVideoCall,
    openVideoCall,
    closeVideoCall,
    handleLogout,
    handleLogin,
    closeMenu,
    openSchemePdf,
    showMenuBar,
    handleLoginForNonUser,
  } = HeaderHooks(propCount, propCart);
  const menuData = useSelector((state) => state.abData.category);

  const [productValue, setproductValue] = useState("");

  const onMainSelected = (value, catid, clickedIndex) => {
    const homePayload = {
      main_cat: [value],
      sub_cat: [],
      page_no: 1,
      min_amount: 0,
      max_amount: 0,
      search_key: "",
      filter: [],
    };
    const listPayload = {
      main_cat: [value],
      sub_cat: [],
      page_no: 1,
      search_key: "",
    };

    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: isHomePage ? homePayload : listPayload,
    });
    if ($(window).width() >= 768) {
      navigate(`/product-list/${value.toLowerCase() + "-" + "jewellery"}`, {
        state: { name: value, userid: "" },
      });
      setproductValue(value);
      ProductService.maincatcount(catid);
    } else {
      ProductService.maincatcount(catid);
      var element = document.getElementById("sidebar_btn" + clickedIndex);
      var element1 = document.getElementById("sidebar_items" + clickedIndex);
      element.classList.toggle("active");
      element1.classList.toggle("show");
    }
  };

  const onSubCatSelected = (mainCat, subCat) => {
    const homePayload = {
      main_cat: [mainCat.title],
      sub_cat: [subCat.title],
      page_no: 1,
      min_amount: 0,
      max_amount: 0,
      search_key: "",
      filter: [],
    };
    const listPayload = {
      main_cat: [mainCat.title],
      sub_cat: [subCat.title],
      page_no: 1,
      search_key: "",
    };
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: isHomePage ? homePayload : listPayload,
    });
    ProductService.subcatcount(subCat._id, mainCat._id);
    if (subCat.title === undefined) {
      navigate(
        `/product-list/${
          mainCat.title.toLowerCase() +
          "-" +
          subCat.toLowerCase() +
          "-" +
          "jewellery"
        }`
      );
    } else {
      navigate(
        `/product-list/${
          mainCat.title.toLowerCase() +
          "-" +
          subCat.title.replace(/[' ']/g, "-").toLowerCase() +
          "-" +
          "jewellery"
        }`
      );
    }
    if ($(window).width() >= 768) {
      closeMenu();
    }
    setShowMenu(false);
  };

  //check if menu selected in home pagg or list page
  const isHomePage = useMemo(() => {
    if (window.location.href.split("/")[3] === "") {
      return true;
    }
    return false;
  }, [window.location]);

  const onGenderSelected = (mainCat, filter) => {
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        main_cat: [mainCat.title],
        sub_cat: [],
        page_no: 1,
        min_amount: 0,
        max_amount: 0,
        search_key: "",
        filter: [filter],
      },
    });
    ProductService.subcatcount(filter._id, mainCat._id);
    if (filter.title === undefined) {
      navigate(
        `/product-list/${
          mainCat.title.toLowerCase() +
          "-" +
          filter.toLowerCase() +
          "-" +
          "jewellery"
        }`,
        {
          state: { name: mainCat.title, filter: filter },
        }
      );
    } else {
      navigate(
        `/product-list/${
          mainCat.title.toLowerCase() +
          "-" +
          filter.title.replace(/[' ']/g, "-").toLowerCase() +
          "-" +
          "jewellery"
        }`,
        {
          state: { name: mainCat.title, filter: filter },
        }
      );
    }
    if ($(window).width() >= 768) {
      closeMenu();
    }
    setShowMenu(false);
  };
  const moveDiamond = () => {
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        main_cat: ["Diamond"],
        sub_cat: ["Polki"],
        page_no: 1,
        min_amount: 0,
        max_amount: 0,
        search_key: "",
        filter: [],
      },
    });
    navigate(`/product-list/diamond-polki`);
  };

  const renderSubcategory = (mainTitle, subCatList) => {
    const ColumnView = [];
    if (subCatList) {
      for (let i = -1; i < subCatList.length / 4; i++) {
        const AnchorView = [];
        let maxRow;
        if (i < subCatList.length / 4 - 1) maxRow = 4;
        else maxRow = subCatList.length % 4 === 0 ? 4 : subCatList.length % 4;

        if (i === -1) {
          AnchorView.push(
            <a onClick={() => onGenderSelected(mainTitle, "men")} key={901}>
              MEN
            </a>
          );
          AnchorView.push(
            <a onClick={() => onGenderSelected(mainTitle, "women")} key={902}>
              WOMEN
            </a>
          );
          AnchorView.push(
            <a onClick={() => onGenderSelected(mainTitle, "kids")} key={903}>
              KIDS
            </a>
          );
        } else {
          for (let j = 0; j < maxRow; j++) {
            AnchorView.push(
              <a
                onClick={() =>
                  onSubCatSelected(mainTitle, subCatList[i * 4 + j])
                }
                key={subCatList[i * 4 + j]._id}
              >
                {subCatList[i * 4 + j].title}
              </a>
            );
          }
        }
        ColumnView.push(<div class="column">{AnchorView}</div>);
      }
      return ColumnView;
    }
    return null;
  };

  const renderSearch = () => {
    return <Search closeSearch={() => closeSearch()} />;
  };
  const renderDailyRates = () => {
    return <Dailyrate closeDailyRate={() => closeDailyRate()} />;
  };
  const renderVideoRequest = () => {
    return <Videorequest closeVideoCall={() => closeVideoCall()} />;
  };

  return (
    <div className="menuH_container flt">
      <div className="header">
        <div
          className={"hamburger" + (showMenu ? " rotate_hamburger" : "")}
          onClick={() => {
            openMenuData();
          }}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>

      <nav className={showMenu ? "menuopen" : "menuclose"}>
        <ul className="other_option hide_desktop flt">
          <li>
            <Link to="/return-policy">
              <LazyLoadImage
                src={header_icon2}
                height="30px"
                width="30px"
                alt="image"
              />
              <br />
              Shipping all over India
            </Link>
          </li>

          <li>
            <a onClick={openDailyRate}>
              <LazyLoadImage
                src={header_icon4}
                height="30px"
                width="30px"
                alt="image"
              />
              <br />
              Daily Rates
            </a>
          </li>
          <li>
            <Link to="/contact" state={{ scrollToBottom: true }}>
              <LazyLoadImage
                src={header_branches}
                height="30px"
                width="30px"
                alt="image"
              />
              <br /> Branches
            </Link>
          </li>
          <li>
            <a onClick={openSearch}>
              <LazyLoadImage
                src={header_icon6}
                height="30px"
                width="30px"
                alt="image"
              />
              <br />
              Search
            </a>
          </li>
          <li>
            <Link onClick={openSchemePdf}>
              <LazyLoadImage
                src={header_icon_scheme}
                height="30px"
                width="30px"
                alt="image"
              />
              <br /> Investment Plans
            </Link>
          </li>
          {isUser ? (
            <li>
              <Link to="/my-details">
                <LazyLoadImage
                  src={header_icon5}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                My Account
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/login" onClick={() => handleLoginForNonUser()}>
                <LazyLoadImage
                  src={header_icon5}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                My Account
              </Link>
            </li>
          )}

          {isUser ? (
            propCount > 0 ? (
              <li>
                <Link to="/wishlist">
                  <LazyLoadImage
                    src={header_icon7}
                    height="30px"
                    width="30px"
                    alt="image"
                  />
                  <br /> Wishlist ({propCount})
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/wishlist">
                  <LazyLoadImage
                    src={header_icon7}
                    height="30px"
                    width="30px"
                    alt="image"
                  />
                  <br /> Wishlist
                </Link>
              </li>
            )
          ) : (
            <li>
              <a onClick={handleLoginForNonUser}>
                {" "}
                <LazyLoadImage
                  src={header_icon7}
                  height="30px"
                  width="30px"
                  alt="image"
                />{" "}
                <br /> Wishlist
              </a>
            </li>
          )}
          {isUser ? (
            propCart > 0 ? (
              <li>
                <Link to="/cart">
                  <b>
                    <LazyLoadImage
                      src={header_icon8}
                      height="30px"
                      width="30px"
                      alt="image"
                    />
                    {<span>{propCart}</span>}
                  </b>
                  <br />
                  Cart{" "}
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/cart">
                  <LazyLoadImage
                    src={header_icon8}
                    height="30px"
                    width="30px"
                    alt="image"
                  />
                  <br />
                  Cart
                </Link>
              </li>
            )
          ) : (
            <li>
              <a onClick={handleLoginForNonUser}>
                {" "}
                <LazyLoadImage
                  src={header_icon8}
                  height="30px"
                  width="30px"
                  alt="image"
                />{" "}
                <br /> Cart{" "}
              </a>
            </li>
          )}
          <li>
            <button onClick={openVideoCall}>Request for video call</button>
          </li>
        </ul>
        {menuData && menuData !== null
          ? menuData.map((main_cat, index) => (
              <div className="megamenu_panel">
                <button
                  id={"sidebar_btn" + index}
                  onClick={() =>
                    onMainSelected(main_cat.title, main_cat._id, index)
                  }
                  key={main_cat._id}
                >
                  {main_cat.title}
                  <span className="fa fa-chevron-right"></span>
                </button>
                <div className="megamenu_list" id={"sidebar_items" + index}>
                  <div className="row" id={main_cat.title}>
                    {showMenuBar &&
                      renderSubcategory(main_cat, main_cat.sub, index)}
                  </div>
                </div>
              </div>
            ))
          : null}
        {/* <div className="megamenu_panel">
          <button id={"sidebar_btn"} onClick={moveDiamond}>
            POLKI
          </button>
        </div> */}

        <div className="megamenu_panel hide_desktop">
          {isUser ? (
            <button onClick={handleLogout}>LOGOUT</button>
          ) : (
            <button onClick={handleLogin}>LOGIN</button>
          )}
        </div>
      </nav>

      {showSearchBar && renderSearch()}
      {showDailyRate && renderDailyRates()}
      {showVideoCall && renderVideoRequest()}
    </div>
  );
};

export default Menu;
